import React, { useEffect, useState } from 'react';

import './tabulator.scss';



// TABULATOR ENTRY
// ========================

interface TabEntryProps {
    label: string;
    sublabel?: string;
    children: React.ReactElement;
}

export const TabEntry: React.FC<TabEntryProps> = ({ children }) => {
    return (
        <div className="_tabEntry">
            {children}
        </div>
    )
}


// TABULATOR BODY
// ========================
interface TabulatorProps {
    children: React.ReactElement<TabEntryProps>[];

}

export const Tabulator: React.FC<TabulatorProps> = ({ children }) => {
    // NAVIGATIONS STATE
    const [labels, setLabels] = useState<{ label: string, sublabel?: string }[]>([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const [minWidth, setMinWidth] = useState(200);
    const [shouldSplit, setShouldSplit] = useState(false);

    const divContainerRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        const _labels = children.map(child => {
            const { label, sublabel } = child.props;
            return { label, sublabel };
        });
        console.log('Setting Labels: ', _labels);
        setLabels(_labels);

    }, [children]);

    useEffect(() => {
        let _shouldSplit = false;
        let _minWidth = 200;
        if (divContainerRef.current) {
            console.log('Tabulator with: ', divContainerRef.current.getBoundingClientRect().width);
            _minWidth = divContainerRef.current.getBoundingClientRect().width / labels.length;
            console.log('Tabulator MinWidth: ', _minWidth);
            if (_minWidth < 150) {
                // Should use a twoRow alignment
                _minWidth = Math.floor(divContainerRef.current.getBoundingClientRect().width / Math.ceil(labels.length / 2)) - 1;
                _shouldSplit = true;
            } else if(_minWidth < 200){
                _minWidth = Math.floor(divContainerRef.current.getBoundingClientRect().width / Math.ceil(labels.length / 2)) - 1;
                _shouldSplit = true;
            } else {
                _minWidth = 200;
                _shouldSplit = false;
            }
        }
        setShouldSplit(_shouldSplit);
        setMinWidth(_minWidth);
    }, [divContainerRef, labels])

    const changeTab = (index: number) => {
        return () => {
            setActiveIndex(index);
        }
    }

    const renderTabs = () => {
        return labels.map((group, i) => {
            return (
                <div className={`_tabWrap ${shouldSplit ? 'splitted' : ''}`} key={i} >
                    <div className={`_tab _index_${i} ${activeIndex === i ? 'active' : ''} ${group.sublabel ? 'hasSublabel' : ''}`} style={{ minWidth: `${minWidth}px` }} onClick={changeTab(i)}>
                        <p>
                            {group.label}
                        </p>
                        {
                            group.sublabel ?
                                <p className="sublabel">
                                    {group.sublabel}
                                </p>
                                : ''
                        }
                    </div>
                </div>)
        })
    }

    return (
        <div className="_tabContainer" ref={divContainerRef}>
            <div className="_tabChooser">
                {renderTabs()}
            </div>
            <div className="_tabBody">

                {children.map((c, i) => {
                    return (
                        <div className={`_tabBodyEntry ${i === activeIndex ? 'active' : ''}`} key={i}>
                            {c}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}