import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import AmazonLogo from "../../../components/images/amazon"
import ImageFooted from '../../../components/widgets/imageFooted';
import SectionNavigate from '../../../components/widgets/sectionNavigate';
import { IMG_Amazon } from '../../../components/images/docs/amazon';
import { Tabulator, TabEntry } from '../../../components/widgets/tabulator';

type DataProps = {
    site: {
        buildTime: string
    }
}

const AmazonRegister: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce - Amazon registro" description="Registrar tu cuenta de Amazon con FiscalPOP" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <AmazonLogo />
            </div>
            <h2>
                Llenar el registro
            </h2>
            <h3>
                ID de vendedor y Token de Acceso
            </h3>
            <p>
                Hay dos campos especiales que se necesitan para completar el registro de FiscalPOP para Amazon, <br />
                <b>Id de vendedor</b> y <b>Token de acceso</b>.
            </p>
            <p>
                Ambos datos se obtienen de tu Seller Central de Amazon, solamente sigue las instrucciónes a continuación para continuar.
            </p>
            <Tabulator>
                <TabEntry label="Paso 1" sublabel="Pagina de registro">
                    <ImageFooted explanation={`1. En la página de registrar FiscalPOP Amazon, requieres el Id de vendedor y el Token de acceso. \n Los campos mostrados con borde azul se obtienen deste tu Seller Central de Amazon`}>
                        <IMG_Amazon file={'register'} />
                    </ImageFooted>
                </TabEntry>
                <TabEntry label="Paso 2" sublabel="Apps en SellerCentral">
                    <ImageFooted explanation={`2. Desde tu Seller Central de Amazon, busca la pestaña de "Apps & Services", la opción de "Manage your Apps" es la que buscas.`}>
                        <IMG_Amazon file={'register2'} />
                    </ImageFooted>
                </TabEntry>
                <TabEntry label="Paso 3" sublabel="Manage Apps Amazon">
                    <ImageFooted explanation={`3. En esta sección verás las aplicaciones de Amazon instaladas, da click en "Authorize new developer".`}>
                        <IMG_Amazon file={'register3'} />
                    </ImageFooted>
                </TabEntry>
                <TabEntry label="Paso 4" sublabel="Authorize developer">
                    <ImageFooted explanation={`4. En esta seccioón darás de alta un nuevo desarrollador, llena los campos con "Developer Name: FiscalPOP" y "Developer ID: 987378576020", después da click en Next.`}>
                        <IMG_Amazon file={'register4'} />
                    </ImageFooted>
                </TabEntry>
                <TabEntry label="Paso 5" sublabel="Confirmar acceso">
                    <ImageFooted explanation={`5. Aqui solamente hay que autorizar el acceso y confirmar con "next".`}>
                        <IMG_Amazon file={'register5'} />
                    </ImageFooted>
                </TabEntry>
                <TabEntry label="Paso 6" sublabel="Tokens de acceso!">
                    <ImageFooted explanation={`6. Esta es la vista más importante, el Seller ID como se muestra en la pantalla, es el ID de vendedor, guárdalo. \n El MWS Auth Token es el Token de acceso, también guardalo. \n Estos dos son los datos que necesitamos para terminar el registro.`}>
                        <IMG_Amazon file={'register6'} />
                    </ImageFooted>
                </TabEntry>
                <TabEntry label="Paso 7" sublabel="Acceso confirmado">
                    <ImageFooted explanation={`7. Cuando termines el proceso, verás en esta lista de "Manage your apps" el listado de FiscalPOP que acabas de realizar.`}>
                        <IMG_Amazon file={'register7'} />
                    </ImageFooted>
                </TabEntry>
                <TabEntry label="Paso 8" sublabel="Termina registro">
                    <ImageFooted explanation={`8. Con estos datos del Seller Central ya podrás terminar el registro y conectar FiscalPOP a tu cuenta de Amazon.`}>
                        <IMG_Amazon file={'register8'} />
                    </ImageFooted>
                </TabEntry>
            </Tabulator>
            <br />
            <br />
            <p>
                Las credenciales obtenidas de Amazon nos permitirá acceder a tus datos durante un año, Amazon considera esto un ciclo, por lo que antes del siguiente año te recordaremos para renovar las credenciales.
            </p>
            <p>
                Las credenciales se renuevan en la vista del paso 7, el botón con el texto "Renew".
            </p>
            <SectionNavigate next={`/ecommerce/amazon/claves-default`} />
        </div>
    </Layout>
)

export default AmazonRegister;