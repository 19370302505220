import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"

type FILE_OPTIONS = 'register' | 'register2' | 'register3' | 'register4' | 'register5' | 'register6' | 'register7' | 'register8' | 'orderList' | 'productos' | 'productosMultiples';

export const IMG_Amazon: React.FC<{ file: FILE_OPTIONS }> = ({ file }) => {
    const data = useStaticQuery(graphql`
    query {
        register: file(relativePath: { eq: "docimages/amazon_register.png" }) {
            childImageSharp {
                fixed (width:950){
                    ...GatsbyImageSharpFixed
                }
            }
        }
        register2: file(relativePath: { eq: "docimages/amazon_register2.png" }) {
            childImageSharp {
                fixed (width:950){
                    ...GatsbyImageSharpFixed
                }
            }
        }
        register3: file(relativePath: { eq: "docimages/amazon_register3.png" }) {
            childImageSharp {
                fixed (width:950){
                    ...GatsbyImageSharpFixed
                }
            }
        }
        register4: file(relativePath: { eq: "docimages/amazon_register4.png" }) {
            childImageSharp {
                fixed (width:950){
                    ...GatsbyImageSharpFixed
                }
            }
        }
        register5: file(relativePath: { eq: "docimages/amazon_register5.png" }) {
            childImageSharp {
                fixed (width:950){
                    ...GatsbyImageSharpFixed
                }
            }
        }
        register6: file(relativePath: { eq: "docimages/amazon_register6.png" }) {
            childImageSharp {
                fixed (width:950){
                    ...GatsbyImageSharpFixed
                }
            }
        }
        register7: file(relativePath: { eq: "docimages/amazon_register7.png" }) {
            childImageSharp {
                fixed (width:950){
                    ...GatsbyImageSharpFixed
                }
            }
        }
        register8: file(relativePath: { eq: "docimages/amazon_register8.png" }) {
            childImageSharp {
                fixed (width:950){
                    ...GatsbyImageSharpFixed
                }
            }
        }
        orderList: file(relativePath: { eq: "docimages/amazon_orderlist.png" }) {
            childImageSharp {
                fixed (width:950){
                    ...GatsbyImageSharpFixed
                }
            }
        }
        productos: file(relativePath: { eq: "docimages/amazon_productos.png" }) {
            childImageSharp {
                fixed (width:950){
                    ...GatsbyImageSharpFixed
                }
            }
        }
        productosMultiples: file(relativePath: { eq: "docimages/amazon_multiproductos.png" }) {
            childImageSharp {
                fixed (width:950){
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
  `)
    if (file === 'register') {
        return (
            <Img
                fixed={data.register.childImageSharp.fixed}
                alt="Registar Fiscalpop en Amazon"
            />
        )
    }
    else if (file === 'register2') {
        return (
            <Img
                fixed={data.register2.childImageSharp.fixed}
                alt="Registar Fiscalpop en Amazon"
            />
        )

    }
    else if (file === 'register3') {
        return (
            <Img
                fixed={data.register3.childImageSharp.fixed}
                alt="Registar Fiscalpop en Amazon"
            />
        )

    }
    else if (file === 'register4') {
        return (
            <Img
                fixed={data.register4.childImageSharp.fixed}
                alt="Registar Fiscalpop en Amazon"
            />
        )

    }
    else if (file === 'register5') {
        return (
            <Img
                fixed={data.register5.childImageSharp.fixed}
                alt="Registar Fiscalpop en Amazon"
            />
        )

    }
    else if (file === 'register6') {
        return (
            <Img
                fixed={data.register6.childImageSharp.fixed}
                alt="Registar Fiscalpop en Amazon"
            />
        )

    }
    else if (file === 'register7') {
        return (
            <Img
                fixed={data.register7.childImageSharp.fixed}
                alt="Registar Fiscalpop en Amazon"
            />
        )

    }
    else if (file === 'register8') {
        return (
            <Img
                fixed={data.register8.childImageSharp.fixed}
                alt="Registar Fiscalpop en Amazon"
            />
        )
    }
    else if (file === 'orderList') {
        return (
            <Img
                fixed={data.orderList.childImageSharp.fixed}
                alt="Lista de pedidos en FiscalPOP Amazon"
            />
        )
    }
    else if (file === 'productos') {
        return (
            <Img
                fixed={data.productos.childImageSharp.fixed}
                alt="Lista de tus productos de Amazon en FiscalPOP"
            />
        )
    }
    else if (file === 'productosMultiples') {
        return (
            <Img
                fixed={data.productosMultiples.childImageSharp.fixed}
                alt="Lista de tus productos multiples de Amazon en FiscalPOP"
            />
        )
    }
}
